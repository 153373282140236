import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../components/Layout';
import BlogRoll from '../../components/BlogRoll';
import Hero from '../../components/Hero';

const BlogIndexPage = () => {
	return (
		<Layout>
			<Helmet>
				<title>Artiklar</title>
			</Helmet>
			<Hero title="Artiklar" />
			<section className="section">
				<div className="container">
					<div className="columns">
						<div className="column is-10 is-offset-1">
							<div className="content">
								<BlogRoll />
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default BlogIndexPage;
