import React from 'react';
import PropTypes from 'prop-types';
import Masonry from 'react-masonry-css';
import { Link, graphql, StaticQuery } from 'gatsby';

const BlogRoll = ({ data, count }) => {
	const { edges: posts } = data.allMarkdownRemark;

	return (
		<Masonry
			breakpointCols={{
				default: 2,
				1024: 1,
			}}
			className="masonry-grid"
			columnClassName="masonry-grid_column"
		>
			{posts &&
				posts.map(({ node: post }) => (
					<article
						key={post.id}
						className={`blog-list-item tile is-child box notification ${
							post.frontmatter.featuredpost ? 'is-featured' : ''
						}`}
					>
						<header>
							<p className="post-meta">
								<Link
									className="title has-text-primary is-size-5"
									to={post.fields.slug}
								>
									{post.frontmatter.title}
								</Link>
								<span className="is-size-7 is-block">
									{post.frontmatter.date}
								</span>
							</p>
						</header>
						<p>{post.excerpt}</p>
						<p>
							<Link className="button" to={post.fields.slug}>
								Fortsätt läsa →
							</Link>
						</p>
					</article>
				))}
		</Masonry>
	);
};

BlogRoll.propTypes = {
	data: PropTypes.shape({
		allMarkdownRemark: PropTypes.shape({
			edges: PropTypes.array,
		}),
	}),
};

const BlogRollQuery = () => (
	<StaticQuery
		query={graphql`
			query BlogRollQuery {
				allMarkdownRemark(
					sort: { order: DESC, fields: [frontmatter___date] }
					filter: { frontmatter: { templateKey: { eq: "article" } } }
				) {
					edges {
						node {
							excerpt(pruneLength: 400)
							id
							fields {
								slug
							}
							frontmatter {
								title
								templateKey
								date(formatString: "DD MMMM, YYYY", locale: "sv")
								featuredpost
							}
						}
					}
				}
			}
		`}
		render={(data, count) => <BlogRoll data={data} count={count} />}
	/>
);

export default BlogRollQuery;
